import { Button } from '@/components/Button'

interface CTAProps {
	href: string
	heading: string
}

export default function CTA({ href,  heading }: CTAProps) {
	return (
		<div className="my-[6rem] sm:my-[8rem] md:my-[10rem] px-site-margin text-center">
			<Button href={href}>{heading}</Button>
		</div>
	)
}
