"use client"

import CTA from "@/components/CTA"
import PageHeader from "@/components/PageHeader"

export default function Error() {
	return (
		<div className="site-wrapper about-layout">

			<div className="mb-[8rem]">
				<PageHeader title={"Oh no!"} />
			</div>

			<div className={`w-full my-[8rem] px-site-margin text-center`}>
				<p className="style-h2">There was an issue. This could be a temporary issue, please try your action again.</p>
			</div>

			<CTA href="/contact" heading={'Get in touch'} />

		</div>
	)
}
