import { cn } from '@/lib/utils'
import { cva, VariantProps } from 'class-variance-authority'
import Link, { LinkProps } from 'next/link'
import { forwardRef } from 'react'

const buttonStyles =
	'inline-block px-4 pt-2 pb-3 style-h4 text-black border-solid border border-black hover:border-blue rounded-weak transition'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	href?: LinkProps['href']
	as?: LinkProps['as']
	locale?: LinkProps['locale']
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, href, children, as, locale, ...props }, ref) => {
	if (href) {
		return (
			<Link href={href} className={cn(buttonStyles, className)} as={as} locale={locale}>
				<span className={'relative mt-[-0.1em] under'}>{children}</span>
			</Link>
		)
	}
	return (
		<button className={cn(buttonStyles, className)} ref={ref} {...props}>
			<span className={'relative mt-[-0.1em]'}>{children}</span>
		</button>
	)
})
Button.displayName = 'Button'

export { Button, buttonStyles }
