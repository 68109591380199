interface PageHeaderProps {
	title: string
	align?: string
}

export default function PageHeader({title, align="left"}:PageHeaderProps) {
	return (
		<div className={`w-full mt-[4rem] sm:mt-[6rem] md:mt-[8rem] px-site-margin ${align === "center" ? "text-center" : "text-left"} [@media(max-width:767px)]:[&_br]:hidden`}>
			<h1 className="style-h1 page-header text-center text-balance">
				<span className="relative top-[0.04em]" dangerouslySetInnerHTML={{ __html: title?.toString()?.replaceAll('\n', '<br />') }} />
			</h1>
		</div>
	)
}