import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export const toUrlFriendly = (str: string) => {
	return str
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '')
}

export function toTitleCase(str: string) {
	if (!str) return str
	return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export function toSentenceCase(str: string) {
	if (!str) return str
	return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

export function sanitize(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return typeof obj === 'string' ? obj.replace(/[\u{0080}-\u{FFFF}]/gu, '') : obj
	}

	let sanitizedObj = Array.isArray(obj) ? [] : {}

	for (let key in obj) {
		let sanitizedKey = key.replace(/[\u{0080}-\u{FFFF}]/gu, '')
		sanitizedObj[sanitizedKey] = sanitize(obj[key])
	}

	return sanitizedObj
}

// See: https://p5js.org/reference/#/p5/map
export const mapRange = (value: number, start1: number, stop1: number, start2: number, stop2: number): number => {
	// first map value from (a..b) to (0..1)
	value = (value - start1) / (stop1 - start1)
	// then map it from (0..1) to (c..d) and return it
	return start2 + value * (stop2 - start2)
}

export function pauseAndMuteOtherMedia(el) {
	if (!el || !["VIDEO", "AUDIO", "MUX-PLAYER"].includes(el.nodeName)) return

	document.querySelectorAll('audio, video, mux-player').forEach((el: any) => {
		let mediaEl = el

		if (el.nodeName === "MUX-PLAYER") {
			mediaEl = el.shadowRoot.querySelector("mux-video").shadowRoot.querySelector("video")
			// el.muted = true
			mediaEl.muted = true
		}

		// PAUSE audio elements
		if (el.nodeName === "AUDIO") {
			mediaEl.pause()
		}

		// Just mute video elements (let them continue to play muted)
		if (el.nodeName === "VIDEO") {
			mediaEl.muted = true
		}
	})

	el.play()
	el.muted = false
}

export function pauseOtherVideos(el) {
	if (!el || !["VIDEO", "MUX-PLAYER"].includes(el.nodeName)) return

	document.querySelectorAll('audio, video, mux-player').forEach((el: any) => {
		let mediaEl = el

		if (el.nodeName === "MUX-PLAYER") {
			mediaEl = el.shadowRoot.querySelector("mux-video").shadowRoot.querySelector("video")
		}

		mediaEl.pause()
	})

	el.pause()
}

export function muteOtherMedia(el) {
	if (!el || !["VIDEO", "AUDIO", "MUX-PLAYER"].includes(el.nodeName)) return

	document.querySelectorAll('audio, video, mux-player').forEach((el: any) => {
		let mediaEl = el

		if (el.nodeName === "MUX-PLAYER") {
			mediaEl = el.shadowRoot.querySelector("mux-video").shadowRoot.querySelector("video")
			// el.muted = true
			mediaEl.muted = true
		}

		// PAUSE audio elements
		if (el.nodeName === "AUDIO") {
			mediaEl.pause()
		}

		// Just mute video elements (let them continue to play muted)
		if (el.nodeName === "VIDEO") {
			mediaEl.muted = true
		}
	})

	el.play()
	el.muted = false
}